<template>
  <v-row class="fill-height ma-0">
    <v-col>
      <v-list class="mt-5 ml-md-16">
        <v-list-item class="d-flex flex-column align-start">
          <h5 class="pl-4 mb-7 grey-33 Caption fz-16 font-weight-regular line-h-20">Upgrade plan</h5>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                <v-img
                  :src="require('../assets/svg/check-success.svg')"
                  width="20"
                  height="20"
                  class="mr-6"
                />
                <span class="grey-33 Caption">No Ads</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                <v-img
                  :src="require('../assets/svg/check.svg')"
                  width="20"
                  height="20"
                  class="mr-5"
                />
                <span class="grey-33" style="white-space: break-spaces"
                  >You get to keep your numbers even without activity</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                <v-img
                  :src="require('../assets/svg/check.svg')"
                  width="20"
                  height="20"
                  class="mr-6"
                />
                <span class="grey-33 Caption" style="white-space: break-spaces"
                  >You have a broader set of numbers to choose from</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-btn
            class="ml-md-4 mt-10 upgrade text-none"
            color="white"
            elevation="0"
            :block="$vuetify.breakpoint.xs"
            :outlined="$vuetify.breakpoint.smAndUp"
            @click="$router.push('purchase')"
            >Go to purchase
          </v-btn>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Upgrade',
  data: () => {
    return {
      goPurchase: false
    }
  },
  components: {}
}
</script>

<style scoped lang="scss">
.v-btn {
  text-transform: capitalize;
}

.upgrade {
  background-color: #5758b0 !important;
  border-color: #5758b0 !important;
  color: white;
}
</style>
