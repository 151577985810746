<template>
  <div class="people-main-block">
  <v-col
          cols="12"
          md=""
          lg="8"
          class="pa-0"
          style="background-color: #f5f5f5; border: 1px solid #f5f5f5;height: 100%"
  >
  <div class="d-flex fill-height w-100">
    <div :class="{ thin: GetToggleSidebar }" class="w-100 people-container" style="position: relative;">
      <v-toolbar elevation="0" class="d-flex  flex-column justify-space-between">
        <v-list-item-content>People</v-list-item-content>
        <v-btn icon class="text-right" @click="closePeople()">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="#5F6368"
            />
          </svg>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div v-else class="people-list-wrapper scroll-thin-4">
        <v-list
          class="py-0"
          elevation="0"
          border="left"
          colored-border
        >
          <v-list-item
            v-if="getFirstContact"
            class="numbers-list__item"
          >
          <v-list-item-avatar color="primary">
            <v-icon color="#fff">mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="pa-0">
            <span class="fz-16 font-weight-medium">{{ getFirstContact.first_name }} {{ getFirstContact.last_name }}</span>
            <span class="fz-14">{{ formatPhone(getFirstContact.phone_number ? getFirstContact.phone_number : ($route.name === 'Messages' ? getFirstContact.num : getFirstContact.direction === 'in' ? getFirstContact.ani : getFirstContact.dnis)) }}</span>
          </v-list-item-content>
          <v-list-item-action v-if="!getFirstContact.first_name && !getFirstContact.last_name">
            <v-btn icon class="text-right" @click="openAddContactDialog(getFirstContact)">
              <v-list-item-icon class="my-4 mx-4">
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4ZM10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2C9.1 2 10 2.9 10 4ZM0 14V16H16V14C16 11.34 10.67 10 8 10C5.33 10 0 11.34 0 14ZM2 14C2.2 13.29 5.3 12 8 12C10.69 12 13.78 13.28 14 14H2ZM19 11V8H22V6H19V3H17V6H14V8H17V11H19Z"
                    fill="#5F6368"
                  />
                </svg>
              </v-list-item-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-else>
            <div class="d-flex items-center">
              <v-tooltip bottom color="dark">
                <template v-slot:activator="{ on, attrs }">
                  <v-img v-bind="attrs" v-on="on" src="../assets/svg/fi_edit.svg" class="cursor-p mr-4" @click="openCreateEditModal(getFirstContact)" />
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom color="dark">
                <template v-slot:activator="{ on, attrs }">
                  <v-img v-bind="attrs" v-on="on" src="../assets/svg/fi_delete.svg" class="cursor-p white--text" @click="openDeleteModal(getFirstContact)" />
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </v-list-item-action>
        </v-list-item>

          <template v-if="getContactList.length > 1">
            <div class="people-divider">
              <div class="people-divider-left"></div>
              <div>All</div>
              <div class="people-divider-right"></div>
            </div>
            <v-list-item
              v-for="(item, index) in getContactList.slice(1)"
              :key="index"
              class="numbers-list__item"
            >
              <v-list-item-avatar color="primary">
                <v-icon color="#fff">mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="pa-0">
                <span class="fz-16 font-weight-medium">{{ item.first_name }} {{ item.last_name }}</span>
                <span class="fz-14">{{ formatPhone(item.phone_number ? item.phone_number : ($route.name === 'Messages' ? item.num : item.direction === 'in' ? item.ani : item.dnis)) }}</span>
              </v-list-item-content>
              <v-list-item-action v-if="!item.first_name && !item.last_name">
                <v-btn icon class="text-right" @click="openAddContactDialog(item)">
                  <v-list-item-icon class="my-4 mx-4">
                    <svg
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4ZM10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2C9.1 2 10 2.9 10 4ZM0 14V16H16V14C16 11.34 10.67 10 8 10C5.33 10 0 11.34 0 14ZM2 14C2.2 13.29 5.3 12 8 12C10.69 12 13.78 13.28 14 14H2ZM19 11V8H22V6H19V3H17V6H14V8H17V11H19Z"
                        fill="#5F6368"
                      />
                    </svg>
                  </v-list-item-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-else>
                <div class="d-flex items-center">
                  <v-tooltip bottom color="dark">
                    <template v-slot:activator="{ on, attrs }">
                      <v-img v-bind="attrs" v-on="on" src="../assets/svg/fi_edit.svg" class="cursor-p mr-4" @click="openCreateEditModal(item)" />
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom color="dark">
                    <template v-slot:activator="{ on, attrs }">
                      <v-img v-bind="attrs" v-on="on" src="../assets/svg/fi_delete.svg" class="cursor-p white--text" @click="openDeleteModal(item)" />
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </div>

      <v-dialog v-model="peopleDialog" width="512">
      <v-card>
        <v-card-title v-if="peopleItem" class="body-1 py-6 px-6">
          {{ peopleDialogType === 'add' ? 'Add' : 'Edit' }} {{ formatPhone(peopleDialogType === 'add' ? getCallNumber : selectedContact.phone_number) }}
        </v-card-title>

        <v-form ref="form" class="px-6 peopleAddInput">
          <v-text-field
            v-model="form.first_name"
            dense
            :rules="nameRules"
            height="36px"
            background-color="#fafafa"
            flat
            outlined
            solo
            required
            placeholder="First name"
            @keypress="onlyLetters"
          ></v-text-field>
          <v-text-field
            v-model="form.last_name"
            dense
            height="36px"
            background-color="#fafafa"
            flat
            outlined
            solo
            required
            placeholder="Last name"
            @keypress="onlyLetters"
          ></v-text-field>
          <v-select
            v-model="form.label_uuid"
            :items="labels"
            item-text="label"
            item-value="uuid"
            height="36px"
            dense
            attach
            :menu-props="{
              offsetY: true,
              positionY: 0,
              contentClass: 'label-dropdown'
            }"
            multiple
            hide-details
            background-color="#fafafa"
            outlined
            placeholder="Select label"
          >
          </v-select>
        </v-form>

        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 font-weight-medium dialog-button"
            elevation="0"
            outlined
            @click="closeDialog"
            >Cancel
          </v-btn>
          <v-btn
            :loading="loadingAdd"
            :disabled="disableAdd"
            small
            color="primary"
            class="px-5 font-weight-medium dialog-button"
            elevation="0"
            @click="peopleDialogType === 'add' ? addPeople() : updatePeople()"
            >{{ peopleDialogType === 'add' ? 'Add' : 'Edit' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog v-model="deleteDialog" width="500">
        <v-card>
          <v-card-title class="body-1">
            Delete {{ selectedContact.first_name }} {{ selectedContact.last_name }} ({{ selectedContact.phone_number }})
            <v-spacer></v-spacer>
            <v-btn @click="closeDeleteModal" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <!-- <span class="font-weight-bold">{{ selectedContact.first_name }} {{ selectedContact.last_name }}</span>
            <br>
            <span class="font-weight-bold">{{ formatPhone(selectedContact.phone_number) }}</span>
            <br> -->
            Are you sure you want to delete this contact?
          </v-card-text>

          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              class="px-5 py-5"
              elevation="0"
              outlined
              @click="closeDeleteModal"
            >Cancel
            </v-btn>
            <v-btn
              :loading="loadingDelete"
              small
              color="primary"
              class="px-5 py-5"
              elevation="0"
              @click="deleteSelectedContact"
            >Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
  </v-col>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { formatPhone } from '../utilities/formatPhone'

export default {
  name: 'People',
  data: () => ({
    loading: false,
    loadingAdd: false,
    loadingDelete: false,
    peopleDialog: false,
    peopleDialogType: 'add',
    form: {
      first_name: '',
      last_name: '',
      label_uuid: []
    },
    nameRules: [v => !!v || 'First name is required'],
    items: [],
    selectedContact: {},
    deleteDialog: false
  }),
  created() {
    this.loadContactList()
  },
  methods: {
    ...mapActions('contacts', ['createContact', 'getContacts', 'deleteContact', 'updateContact']),
    ...mapActions(['showAlert']),
    formatPhone,
    openCreateEditModal(item) {
      this.selectedContact = item
      this.form.first_name = item.first_name
      this.form.last_name = item.last_name
      if (item.userLabel.length) {
        const labels = this.labels.filter(el => item.userLabel.findIndex(e => e.uuid === el.uuid) >= 0)
        if (labels.length) {
          this.form.label_uuid = labels.map(e => e.uuid)
        }
      }
      this.peopleDialogType = 'edit'
      this.peopleDialog = true
    },
    openDeleteModal(item) {
      this.selectedContact = item
      this.deleteDialog = true
    },
    closeDeleteModal() {
      this.selectedContact = {}
      this.deleteDialog = false
      this.loadingDelete = false
    },
    openAddContactDialog(item) {
      this.selectedContact = item
      this.peopleDialogType = 'add'
      this.peopleDialog = true
    },
    async loadContactList() {
      try {
        this.loading = true
        await this.getContacts()
        this.items = this.$store.state.contacts.contacts
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loading = false
      }
    },
    async deleteSelectedContact() {
      try {
        this.loadingDelete = true
        const res = await this.deleteContact(this.selectedContact.uuid)
        if (res) {
          this.closeDeleteModal()
          this.showAlert({ message: 'Contact was successfully deleted' })
          this.loadContactList()
          this.$emit('reload')
        }
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingDelete = false
      }
    },
    closePeople() {
      this.$store.dispatch('showPeopleAction', false)
    },
    closeDialog() {
      this.$refs.form.resetValidation()

      this.form.first_name = ''
      this.form.last_name = ''
      this.form.label_uuid = []
      this.selectedContact = {}
      this.peopleDialog = false
      this.peopleDialogType = 'add'
    },
    async updatePeople() {
      try {
        this.loadingAdd = true
        const payload = {
          first_name: this.form.first_name,
          phone_number: this.selectedContact.phone_number
        }

        if (this.form.last_name) payload.last_name = this.form.last_name
        if (this.form.label_uuid.length) payload.label_uuid = this.form.label_uuid

        const success = await this.updateContact({
          uuid: this.selectedContact.uuid,
          payload
        })

        if (!success) return
        this.showAlert({ message: 'Contact was successfully updated' })
        this.closeDialog()
        this.loadContactList()
        this.$emit('reload')
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingAdd = false
      }
    },
    async addPeople() {
      try {
        const isValid = this.$refs.form.validate()

        if (!isValid) return
        this.loadingAdd = true
        const payload = {
          first_name: this.form.first_name,
          phone_number: this.$route.name === 'Messages'
            ? this.selectedContact.num
            : this.selectedContact.direction === 'in'
              ? this.selectedContact.ani
              : this.selectedContact.dnis
        }

        if (this.form.last_name) payload.last_name = this.form.last_name
        if (this.form.label_uuid.length) payload.label_uuid = this.form.label_uuid

        const success = await this.createContact(payload)

        if (!success) return
        this.showAlert({ message: 'Contact was successfully created' })
        this.closeDialog()
        this.loadContactList()
        this.$emit('reload')
      } catch (err) {
        this.showAlert({ error: 'Sorry, there was something wrong while creating contact' })
        throw new Error(err)
      } finally {
        this.loadingAdd = false
        this.closeDialog()
      }
    },
    onlyLetters (event) {
      if (!/^[A-Za-z\d']+$/.test(event.key)) return event.preventDefault()
    }
  },
  computed: {
    ...mapState(['showPeople']),
    ...mapGetters('calls', ['callItem', 'voicemailItem']),
    ...mapGetters('messages', ['messageItem']),
    ...mapGetters('labels', ['labels']),
    ...mapGetters(['GetToggleSidebar']),
    peopleItem () {
      return this.$route.name === 'Voicemails' ? this.voicemailItem : this.$route.name === 'Messages' ? this.messageItem : this.callItem
    },
    getCallNumber () {
      return this.peopleItem ? (this.$route.name === 'Messages' ? this.peopleItem.num : this.peopleItem.direction === 'in' ? this.peopleItem.ani : this.peopleItem.dnis) : ''
    },
    getContactList () {
      if (this.getCallNumber) {
        const foundItem = this.items.find(item => item.phone_number === this.getCallNumber)
        if (!foundItem) {
          return this.peopleItem ? [this.peopleItem, ...this.items] : this.items
        }
        const remainingItems = this.items// .filter(item => item.phone_number !== this.getCallNumber) FREES-1528
        return [foundItem, ...remainingItems]
      }
      return this.items
    },
    getFirstContact () {
      return this.getContactList.length > 0 ? this.getContactList[0] : null
    },
    disableAdd () {
      return !this.form.first_name
    }
  }
}
</script>

<style lang="scss">
.label-dropdown .v-list-item {
  .v-list-item__title {
    font-weight: 500;
  }
  .v-list-item__action {
    margin: 0 10px 0 0;
  }
}
</style>

<style scoped>
.people-main-block {
  width: 320px;
  margin: -1px 0px 0px 0px;
}
.dialog-button {
  font-size: 14px;
  line-height: 20px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.people-list-wrapper {
  height: calc(100vh - 64px - 76px);
  overflow-y: auto;
  background-color: white;
}
.people-container {
  min-width: 320px;
}
.people-divider {
  padding: 8px 0 8px 0;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}
.people-divider-left {
  width: 25px;
  height: 2px;
  background: #f3f3f3;
}
.people-divider-right {
  width: 100%;
  height: 2px;
  background: #f3f3f3;
}

@media (max-width: 1600px) {
  .people-main-block {
    margin: -1px 1px 0px 0px;
    width: 360px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .people-container {
    min-width: 360px;
  }

}

@media (min-width: 1263px) and (max-width: 1340px) {
  .people-container {
    min-width: 360px !important;
  }
}

@media (min-width: 1263px) and (max-width: 1550px) {
  .people-container.thin {
    min-width: 360px !important;
  }
}
</style>
