<template>
  <v-row class="fill-height ma-0">
    <NumbersList ref="numberList" @sendMessage="$router.push('/new-message')" @loading="e => loading = e" />
    <v-col
      v-if="window.width > 575.98"
      cols="12"
      md=""
      lg="8"
      class="pa-0"
      style="
        background-color: #f5f5f5;
        border: 1px solid #f5f5f5;
      "
    >
      <div class="d-flex fill-height">
        <div v-if="messageItem" class="w-100 d-flex flex-column">
          <v-toolbar class="elevation-0" style="flex:0">
            <p class="mb-0">To</p>
            <v-text-field
              ref="to"
              solo
              flat
              :value="toFormatted"
              :maxlength="maxlength"
              :minlength="minlength"
              @input="phoneFieldFilter($event)"
              hide-details
              @blur="handleBlur"
              @focus="handleFocus"
              @keypress="onlyNumbers"
              @paste.prevent="onPasteNumber"
            ></v-text-field>
            <v-spacer></v-spacer>

             <v-tooltip bottom color="dark" v-if="num">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="call">
                  <v-icon>mdi-phone</v-icon>
                </v-btn>
              </template>
              <span>Call</span>
            </v-tooltip>

            <ActionsMenu @reload="$refs.numberList.loadMessages(true)" @removedChat="setMessageItem({ to: '' })" />
          </v-toolbar>

          <v-divider v-if="!loading"></v-divider>
          <v-progress-linear
            style="height: 1px"
            v-else
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-if="messages.length > 0" class="white d-flex justify-end flex-column w-100" @click="readSms" style="flex:1;">
            <v-list class="messages-list invisible-scrollbar" style="padding: 8px 10px;" ref="msgBlock">
              <v-list-item v-for="item in messages" :key="item.uuid" style="display: flex"
                           class="w-100 d-flex"
                           :class="[item.direction === 'out' && 'justify-end']">
                <v-list-item-avatar color="#27AE60" v-if="item.direction === 'in'" class="mb-6">
                  <v-icon color="#fff">mdi-account</v-icon>
                </v-list-item-avatar>
                <div style="max-width: 80%; width: fit-content">
                  <v-list-item-content class="message">{{item.text}}</v-list-item-content>
                  <div style="display: flex; align-items: center; gap: 12px" v-if="item.loading">
                    <v-progress-circular indeterminate size="12" color="primary"></v-progress-circular>
                    <div>Sending...</div>
                  </div>
                  <div class="fz-12 pl-4" v-else>{{ formatDate(item.direction === 'in' ? item.receive_on : item.sent_on) }}</div>
                </div>
                <v-list-item-avatar color="#27AE60" v-if="item.direction === 'out'" class="mb-6">
                  <v-icon color="#fff">mdi-account</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="d-flex align-center justify-center" style="flex:1">
            <div class="d-flex flex-column align-center">
              <v-progress-circular v-if="globalLoading" indeterminate size="32" color="primary"></v-progress-circular>
              <p class="grey--text" v-else>No messages</p>
              <GoogleAdSense adSlot="6685324668" :stylesSm="showPeople" sequence="3" />
            </div>
          </div>
          <v-footer
            class="pa-0"
            @click="readSms"
            style="width: 100%;position: relative;"
          > <!-- position: absolute; bottom: 0;  -->
            <div v-if="disableSms" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 1; background-color: rgba(95,99,104,0.3)"></div>
            <v-form style="background-color: white; width: 100%">
              <v-container>
                <v-row class="d-flex justify-lg-space-between flex-lg-nowrap flex-row my-1">
                  <div class="w-100 d-flex align-center">
                    <!--                    <div class="d-flex justify-center imageDialog">
                      <v-dialog
                          v-model="dialog"
                          width="1052px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              v-bind="attrs"
                              v-on="on"
                          >mdi-image-area
                          </v-icon>
                        </template>
                        <v-card class="overflow-hidden">
                          <v-card-title class="body-1 py-6 px-6">
                            <span class="text-h6">Select a file</span>
                            <v-spacer></v-spacer>
                            <v-btn @click="dialog = false" small icon>
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-card-title>

                          <v-card-text class="pb-14 px-0 pl-2" >
                            <v-tabs class="dialogTabStyle" v-model="model">
                              <v-tab>You photos</v-tab>
                              <v-tab>Photo album</v-tab>
                              <v-tab>Upload photos</v-tab>
                            </v-tabs>

                            <v-row class="d-flex align-content-space-around justify-center  my-16">
                              <div class="fileUploadStyle">
                                <v-card-text>
                                  <h1 class="gray-33"> Drag photos here</h1>
                                </v-card-text>
                                <p class="grey&#45;&#45;text text-center">or</p>
                                <div>
                                  <v-btn
                                      color="primary"
                                      class="text-none"
                                      round
                                      depressed
                                      :loading="isSelecting"
                                      @click="onButtonClick"
                                  >
                                    {{ defaultButtonText }}
                                  </v-btn>
                                  <input
                                      ref="uploader"
                                      class="d-none"
                                      type="file"
                                      accept="image/*"
                                  >
                                </div>
                              </div>
                            </v-row>

                          </v-card-text>
                          <v-card-actions class="pb-6">
                            <v-spacer></v-spacer>
                            <v-btn
                                class="dialogButtonStyle mx-2"
                                depressed
                                @click="dialog = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                class="dialogButtonStyleDisable mx-2"
                                depressed
                                disabled
                                @click="dialog = false"
                            >
                              Select
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>-->

                    <!--TODO hide the icon gallery for a while-->
<!--                    <v-btn-->
<!--                      class="text-none simple-button"-->
<!--                      depressed-->
<!--                      small-->
<!--                      elevation="0"-->
<!--                      @click="sendMessage"-->
<!--                    >-->
<!--                      <v-img-->
<!--                        :src="require('../assets/svg/photo.svg')"-->
<!--                        contain-->
<!--                        max-width="min-content"-->
<!--                      />-->
<!--                    </v-btn>-->
                      <!-- :append-icon="icon" -->
                    <v-text-field
                      v-model.trim="message"
                      ref="messageBox"
                      flat
                      hide-details
                      dense
                      :loading="loadingSending"
                      filled
                      :disabled="disableSms"
                      clear-icon="mdi-close-circle"
                      clearable
                      label="Type to message"
                      type="text"
                      outlined
                      solo
                      elevation="0"
                      @focus="readChat"
                      @keydown.enter.prevent="sendMessage"
                      @click:clear="clearMessage"
                    >
                    </v-text-field>
<!--                    TODO hide the emoji picker for a while-->
<!--                    <div class="emoji">-->
<!--                      <div class="wrapper">-->
<!--                        <emoji-picker @emoji="append">-->
<!--                          <template v-slot:emoji-invoker="{ events: { click: clickEvent } }">-->
<!--                            <v-img-->
<!--                              style="cursor: pointer;"-->
<!--                              :src="require('../assets/svg/emoji.svg')"-->
<!--                              @click.prevent.stop="clickEvent"-->
<!--                              contain-->
<!--                              width="24"-->
<!--                              height="24"-->
<!--                              max-width="min-content"-->
<!--                            />-->
<!--                          </template>-->
<!--                          <template v-slot:emoji-picker="{ emojis, insert }">-->
<!--                            <div class="emoji-picker" :style="{ top: '-330px', left: '-210px' }">-->
<!--                              <div>-->
<!--                                <div v-for="(emojiGroup, category) in emojis" :key="category">-->
<!--                                  <span class="caption">{{ category }}</span>-->
<!--                                  <div class="emojis">-->
<!--                                  <span-->
<!--                                    v-for="(emoji, emojiName) in emojiGroup"-->
<!--                                    :key="emojiName"-->
<!--                                    @click="insert(emoji)"-->
<!--                                    :title="emojiName"-->
<!--                                  >{{ emoji }}</span>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </template>-->
<!--                        </emoji-picker>-->
<!--                      </div>-->
<!--                    </div>-->
                    <!-- @click:append="changeIcon" -->
                    <v-tooltip top content-class="v-tooltip--top" :disabled="!profileBalanceIsZero">
                      <template v-slot:activator="{ on }">
                        <div v-on="on" class="text-none simple-button">
                          <v-btn
                            :loading="loadingSending"
                            :disabled="!message || (message && !message.trim()) || blockSMSBtn || !isValidPhoneLength || disableSms"
                            class="text-none simple-button"
                            style="background:none !important"
                            :style="message ? 'filter: brightness(0) saturate(100%) invert(39%) sepia(9%) saturate(278%) hue-rotate(173deg) brightness(94%) contrast(91%);' : ''"
                            depressed
                            small
                            elevation="0"
                            @click="sendMessage"
                          >
                            <v-img
                              :src="require('../assets/svg/fi_send.svg')"
                              contain
                              max-width="min-content"
                            />
                          </v-btn>
                        </div>
                      </template>
                      <span>Your balance is 0, only US or Canada is available</span>
                    </v-tooltip>
<!--                    <v-btn-->
<!--                      :disabled="!message"-->
<!--                      class="text-none simple-button"-->
<!--                      style="background:none !important"-->
<!--                      :style="message ? 'filter: brightness(0) saturate(100%) invert(39%) sepia(9%) saturate(278%) hue-rotate(173deg) brightness(94%) contrast(91%);' : ''"-->
<!--                      depressed-->
<!--                      small-->
<!--                      elevation="0"-->
<!--                      @click="sendMessage"-->
<!--                    >-->
<!--                      <v-img-->
<!--                        :src="require('../assets/svg/fi_send.svg')"-->
<!--                        contain-->
<!--                        max-width="min-content"-->
<!--                      />-->
<!--                    </v-btn>-->
                  </div>
                </v-row>
              </v-container>
            </v-form>
          </v-footer>
        </div>
        <v-list-item v-else-if="!loading" class="fill-height text-center">
          <v-list-item-content>
            <v-list-item-title style="font-size:32px">Hi {{userProfile ? userProfile.firstName : ''}}!</v-list-item-title>
            <v-list-item-subtitle class="mt-4">You’re all caught up</v-list-item-subtitle>
            <GoogleAdSense adSlot="6685324668" styles="display:inline-block;min-width:120px;max-width:360px;width:100%;min-height:200px;max-height:400px;height:100%" sequence="3" />
          </v-list-item-content>
        </v-list-item>
        <People v-if="showPeople" @reload="$refs.numberList.loadMessages(true)" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import ActionsMenu from '@/components/ActionsMenu.vue'
import NumbersList from '@/components/Numbers/NumbersList.vue'
import resizeMixin from '@/mixins/resize.mixin'
import People from '@/views/People'
// import EmojiPicker from 'vue-emoji-picker'
import npaUsCad from '../constant/npa_us_cad'
import GoogleAdSense from '../components/GoogleAdSense.vue'
import { formatPhone } from '../utilities/formatPhone'

export default {
  name: 'Messages',
  mixins: [resizeMixin],
  props: ['title'],
  components: {
    GoogleAdSense,
    NumbersList,
    ActionsMenu,
    People
    // EmojiPicker
  },
  data: () => ({
    loadingSending: false,
    total: 0,
    take: 20,
    skip: 0,
    messages: [],
    maxlength: 11, // 13
    minlength: 10,
    peopleDialog: false,
    model: 2,
    defaultButtonText: 'Select photos from your phone',
    selectedFile: null,
    isSelecting: false,
    dialog: false,
    dialog2: false,
    password: 'Password',
    show: false,
    message: '',
    to: '',
    marker: true,
    iconIndex: 0,
    icons: [
      'mdi-emoticon',
      'mdi-emoticon-cool',
      'mdi-emoticon-dead',
      'mdi-emoticon-excited',
      'mdi-emoticon-happy',
      'mdi-emoticon-neutral',
      'mdi-emoticon-sad',
      'mdi-emoticon-tongue'
    ],
    profile: [],
    loading: false,
    globalLoading: false,
    disableSms: false
  }),
  created() {
    this.getProfile()
    if (this.num) {
      this.loadMessages(true)
    }
  },
  mounted () {
    if (this.$route.query.n) {
      this.setMessageItem({ num: this.$route.query.n })
    }
  },
  beforeDestroy() {
    this.removeMsgBlockScrollListener()
  },
  computed: {
    icon() {
      return this.icons[this.iconIndex]
    },
    ...mapState(['showPeople']),
    // eslint-disable-next-line no-undef
    ...mapGetters({
      messageTo: 'getMessageTo',
      sendMessageTo: 'getMessageOnCall',
      userProfile: 'userProfile',
      messageItem: 'messages/messageItem'
    }),
    toFormatted() {
      if (this.messageTo || !this.messageItem.num) return this.to
      return this.formatPhone(this.messageItem.num)
    },
    listMessages () {
      return this.$store.state.messages.listMessages
    },
    num() {
      return this.messageItem?.num
    },
    wsMessages() {
      return this.$store.getters['messages/messages']
    },
    getWsMessagesByNumber() {
      return this.num in this.wsMessages ? this.wsMessages[this.num] : []
    },
    isValidPhoneLength() {
      if ((this.phoneLength <= 11 && [+this.to[0], +this.to[1]].includes(0)) || (this.phoneLength === 11 && +this.to[0] !== 1)) {
        return false
      }
      // if (this.phoneLength === 12 && ([+this.to[1], +this.to[2]].includes(0) || +this.to[1] !== 1)) {
      //   return false
      // }
      // if (this.phoneLength === 13 && ([+this.to[2], +this.to[3]].includes(0) || +this.to[2] !== 1)) {
      //   return false
      // }
      return this.phoneLength <= this.maxlength && this.phoneLength >= this.minlength
    },
    profileBalanceIsZero() {
      return +this.userProfile.balance === 0
    },
    phoneLength() {
      return this.to?.length
    },
    blockSMSBtn() {
      if (this.profileBalanceIsZero) {
        if (this.phoneLength === 10) {
          return !this.isNpaUsCad(this.to.slice(0, 3))
        }
        if (this.phoneLength === 11) {
          return !this.isNpaUsCad(this.to.slice(1, 4))
        }
      }
      return false
    }
  },
  watch: {
    '$store.state.sendMessageTo': function (newVal) {
      if (!this.$refs.to || !newVal) return
      this.to = newVal
      // this.$refs.to.focus()
    },
    messageTo: function (value) {
      if (!this.$refs.to) return
      value && this.$refs.to.focus()
    },
    sendMessageTo: function () {
      if (!this.$refs.to) return
      this.$refs.to.focus()
    },
    messageItem: {
      deep: true,
      handler () {
        if ('to' in this.messageItem && this.messageItem.to === '') {
          this.to = ''
          return
        }
        if (!this.messageItem.num) return
        this.to = this.messageItem.num
        this.readSms()
      }
    },
    getWsMessagesByNumber: function(newValue) {
      const lastMsgItem = newValue.length > 1 ? newValue[newValue.length - 1] : newValue[0]
      if (lastMsgItem) {
        const isExist = this.messages.findIndex(item => item.sms_uuid === lastMsgItem.sms_uuid)
        if (isExist !== -1) {
          this.$set(this.messages, isExist, {
            ...this.messages[isExist],
            ...lastMsgItem
          })
        } else {
          this.messages.push(lastMsgItem)
        }
        this.scrollDown()
      }
    },
    num(value) {
      if (value) {
        this.messages = []
        this.total = 0
        this.take = 20
        this.skip = 0
        this.$store.commit('messages/clearWsMessages', value)
        this.loadMessages(true)
      } else {
        this.clearMessageBox()
      }
    },
    to(newVal) {
      this.loadMessagesNumber(newVal)
    }
  },
  methods: {
    ...mapActions(['getUserProfile', 'showAlert', 'getSMSList']),
    ...mapActions('numbers', ['sendSMS']),
    ...mapMutations('messages', ['setMessageItem']),
    ...mapMutations(['changeMessageTo']),
    ...mapMutations('sip', ['changePhoneNumber']),
    formatPhone,
    call() {
      this.changePhoneNumber(this.messageItem.num)
    },
    async readChat() {
      try {
        const smsLength = this.messages.length
        if (this.to && smsLength > 0 && !this.messages[smsLength - 1].is_read && this.messages[smsLength - 1].direction === 'in') {
          const lastItem = this.messages[smsLength - 1]
          const res = await this.$store.dispatch('messages/markSmsAsRead', this.to)
          if (res.success) {
            this.$store.commit('messages/updateListMessage', {
              ...lastItem,
              is_read: true
            })
            this.$store.commit('messages/updateUnreadCount', { action: 'minus' })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    isNpaUsCad(npa) {
      return npaUsCad.find(item => npa === item)
    },
    scrollDown() {
      this.$nextTick(() => {
        if (this.$refs.msgBlock?.$el) this.$refs.msgBlock.$el.scrollTop = this.$refs.msgBlock?.$el?.scrollHeight
      })
    },
    phoneFieldFilter (value) {
      if (value.length > this.maxlength) {
        this.to = value.substring(0, this.maxlength)
        return
      }
      this.to = value
    },
    onPasteNumber (evt) {
      let filter = evt.clipboardData.getData('text').replace(/[^\d]/g, '')
      if (filter.length > this.maxlength) {
        filter = filter.substring(0, this.maxlength)
      }
      this.to = filter
    },
    append(value) {
      this.message += value
    },
    async getProfile() {
      const data = await this.getUserProfile()
      this.profile = data
    },
    handleBlur() {
      this.$store.commit('changeMessageTo', false)
    },
    handleFocus() {
      this.$store.commit('changeMessageTo', true)
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
    },
    async sendMessage() {
      if (!this.message.trim()) return
      try {
        this.loadingSending = true
        if (!this.to) {
          this.showAlert({ error: 'Please enter destination number' })
          return
        }
        if (!this.profile.number) {
          this.showAlert({ error: 'You don\'t have a number' })
          return
        }
        const smsData = {
          from: this.profile.number,
          to: this.to,
          message: this.message
        }
        const res = await this.sendSMS(smsData)
        // const res = {
        //   success: true,
        //   data: {
        //     user_uuid: '',
        //     ani: '19091231231',
        //     dnis: '19091231231',
        //     sms_uuid: '',
        //     sent_on: '2024-01-10T15:10:43.564Z',
        //     text: 'test from stage freefone portal 5',
        //     conversation_uuid: '',
        //     ani_country: null,
        //     ani_state: null,
        //     ani_zip: null,
        //     ani_city: null,
        //     dnis_country: null,
        //     dnis_state: null,
        //     dnis_zip: null,
        //     dnis_city: null,
        //     archivedAt: null
        //   }
        // }
        if (res.success) {
          this.messages.push({
            ...res.data,
            direction: 'out',
            loading: true
          })
          this.scrollDown()
          this.message = ''
          // setTimeout(() => {
          //   this.messages[this.messages.length - 1].loading = false
          // }, 5000)
        } else {
          this.showAlert({ error: res.message || res.success || JSON.stringify(res) })
        }
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingSending = false
        this.dialog2 = true
      }
    },
    clearMessage() {
      this.message = ''
    },
    changeIcon() {
      this.iconIndex === this.icons.length - 1
        ? (this.iconIndex = 0)
        : this.iconIndex++
    },
    clearMessageBox() {
      this.removeMsgBlockScrollListener()
      this.skip = 0
      this.take = 0
      this.setMessageItem({})
      this.messages = []
    },
    async loadMessagesNumber(number) {
      if (!number || number.length < this.minlength) {
        this.clearMessageBox()
        return
      }
      const smsListItem = this.listMessages.find(item => item.num === number)
      if (smsListItem) {
        this.setMessageItem(smsListItem)
        return
      }
      this.setMessageItem({
        num: number,
        direction: 'out',
        text: '',
        is_spam: false,
        is_read: true,
        sms_uuid: ''
      })
    },
    async loadMessages(reload = false) {
      try {
        if (reload) {
          this.globalLoading = true
        } else {
          this.loading = true
        }
        if (!this.skip) {
          this.$emit('loading', true)
        }
        const params = {
          conversation_number: this.messageItem.num,
          orderType: 'DESC',
          orderBy: 'createdAt',
          take: reload ? this.skip + this.take : this.take,
          skip: reload ? 0 : this.skip
        }
        const data = await this.getSMSList(params)

        if (this.skip > 0 && !reload) return this.messages.unshift(...data.data.reverse())

        this.messages = data.data.reverse()
        this.total = data.total
        this.$nextTick(() => {
          const msgBlock = this.$refs.msgBlock?.$el
          if (msgBlock) {
            msgBlock.addEventListener('scroll', this.handleScroll)
            msgBlock.scrollTop = msgBlock.scrollHeight
          }
        })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        if (reload) {
          this.globalLoading = false
        } else {
          this.loading = false
        }
      }
    },
    removeMsgBlockScrollListener() {
      if (this.$refs.msgBlock?.$el) {
        this.$refs.msgBlock.$el.removeEventListener('scroll', this.handleScroll)
      }
    },
    async handleScroll (e) {
      if (this.loading || this.skip + this.take > this.total) return
      if (e.target.scrollTop <= 200) {
        const oldScrollHeight = e.target.scrollHeight
        this.skip += this.take
        await this.loadMessages()
        this.$nextTick(() => {
          const newScrollHeight = e.target.scrollHeight
          e.target.scrollTop += newScrollHeight - oldScrollHeight
        })
      }
    },
    readSms() {
      this.$refs.numberList.readSms(this.messageItem)
    }
  }
}
</script>

<style lang="scss">
.dialogTabStyle {
  margin-bottom: 98px;
  border-bottom: 1px solid #dfdfdf;

  .v-tab {
    text-transform: inherit;
    font-weight: 400;
    color: #333333;
  }

  .v-tab--active {
    font-weight: 500;
  }
}

.gray-33 {
  color: #333333;
  font-weight: 400;
}

.fileUploadStyle {
  h1 {
    font-size: 32px;
  }
}

.imageDialog {
  width: 45px;
}

.dialogButtonStyle {
  background: #ffffff !important;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  color: #5758b0 !important;
  width: 90px;
}

.dialogButtonStyleDisable {
  background: #bdbdbd !important;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  color: white !important;
  width: 90px;
}

.message {
  background: #EEEEEE;
  border-radius: 24px;
  padding: 8px 16px;
}
//.v-input__append-inner {
//  margin-right: 20px;
//}
.emoji {
  position: absolute;
  top: 24px;
  right: 57px;
  z-index: 50;
}

.wrapper {
  position: relative;
  display: inline-block;
}
.emoji-picker {
  top: 24px;
  right: 57px;
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow-y: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
.messages-list {
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 144px - 67px) !important;
}
</style>
