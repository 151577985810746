<template>
  <v-row class="fill-height ma-0">
    <NumbersList ref="numberList" />
    <v-col
      v-if="window.width > 575.98"
      cols="12"
      md=""
      lg="8"
      class="pa-0"
      style="background-color: #f5f5f5; border: 1px solid #f5f5f5"
    >
      <div class="d-flex fill-height">
        <div class="w-100" style="position: relative">
          <v-toolbar v-if="callItem" elevation="0">
            <template>
              <v-toolbar-title v-if="hasCallName && callItem.direction === 'in'" class="pa-0 d-flex flex-column">
                <span class="fz-20">{{ callItem.caller_first_name }} {{ callItem.caller_last_name }}</span>
                <span class="fz-14">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
              <v-toolbar-title v-else-if="hasCallName && callItem.direction === 'out'" class="pa-0 d-flex flex-column">
                <span class="fz-20">{{ callItem.callee_first_name }} {{ callItem.callee_last_name }}</span>
                <span class="fz-14">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
              <v-toolbar-title v-else class="pa-0">
                <span class="fz-20">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
            </template>
            <v-spacer></v-spacer>

            <v-tooltip bottom color="dark">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="call">
                  <v-icon>mdi-phone</v-icon>
                </v-btn>
              </template>
              <span>Call</span>
            </v-tooltip>

            <v-tooltip bottom color="dark">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="sendMessage" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-message-reply-text-outline mdi-flip-h</v-icon>
                </v-btn>
              </template>
              <span>Message</span>
            </v-tooltip>

            <ActionsMenu @reload="$refs.numberList.reloadNumberList(true)" />
          </v-toolbar>
          <v-divider></v-divider>
          <v-card class="fill-height" elevation="0">
            <v-list class="fill-height">
              <v-list-item v-if="callItem">
                <v-list-item-icon color="primary" class="ml-0 mr-3">
                  <v-icon v-if="callItem.is_spam" color="red">mdi-error</v-icon>
                  <v-icon v-if="callItem.direction === 'in'" color="green">mdi-call-received</v-icon>
                  <v-icon v-if="callItem.direction === 'out'" color="red">mdi-call-made</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ callItem.is_spam ? 'Suspected spam' : callItem.direction === 'in' ? 'Inbound call' : 'Outbound call'}}</v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{formatDate(callItem.createdAt)}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="fill-height text-center">
                <v-list-item-content>
                  <v-list-item-title style="font-size:32px">Hi {{userProfile ? userProfile.firstName : ''}}!</v-list-item-title>
                  <v-list-item-subtitle class="mt-4">You’re all caught up</v-list-item-subtitle>
                  <GoogleAdSense adSlot="6685324668" :stylesSm="showPeople" sequence="3" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        <People class="float-left" v-if="showPeople" @reload="$refs.numberList.reloadNumberList(true)" />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import ActionsMenu from '@/components/ActionsMenu.vue'
import NumbersList from '@/components/Numbers/NumbersList.vue'
import resizeMixin from '@/mixins/resize.mixin'
import People from '@/views/People'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import GoogleAdSense from '../components/GoogleAdSense.vue'
import { formatPhone } from '../utilities/formatPhone'

export default {
  name: 'Calls',
  data: () => ({
  }),
  mixins: [resizeMixin],
  components: {
    GoogleAdSense,
    People,
    NumbersList,
    ActionsMenu
  },
  computed: {
    ...mapState(['showPeople']),
    ...mapGetters(['userProfile']),
    ...mapGetters('calls', ['callItem']),
    hasCallName() {
      return (this.callItem.direction === 'in' && (this.callItem.caller_first_name || this.callItem.caller_last_name)) ||
        (this.callItem.direction === 'out' && (this.callItem.callee_first_name || this.callItem.callee_last_name))
    },
    getCallNumber () {
      return this.callItem.direction === 'in' ? this.callItem.ani : this.callItem.dnis
    }
  },
  created() {
    this.checkNumber()
  },
  methods: {
    ...mapActions(['getUserProfile']),
    ...mapMutations('sip', ['changePhoneNumber']),
    formatPhone,
    async checkNumber () {
      if (!this.userProfile) await this.getUserProfile()
    },
    getPhoneNumber() {
      if (isNaN(+this.getCallNumber)) {
        return this.getCallNumber === this.callItem.ani ? this.callItem.dnis : this.callItem.ani
      }
      return this.getCallNumber
    },
    call() {
      this.changePhoneNumber(this.getPhoneNumber())
    },
    sendMessage () {
      this.$router.push({ name: 'Messages', query: { n: this.getPhoneNumber() } })
    }
  }
}
</script>

<style>
.call-phone-icon {
  display: none;
}

.v-list-item:hover .call-phone-icon {
  display: block;
}
</style>
